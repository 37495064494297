<template>
  <div class="trying-activity-inscriptions" v-if="canView">
    <page-header :title="title" icon="fa fa-school-lock" :links="getLinks()">
    </page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="!isLoading(loadingName)" ref="excelMe">
      <div class="sub-menu">
        <b-row>
          <b-col cols="4">
            <b-input type="text" v-model="entityFilter" placeholder="Filtrer par nom de famille">
            </b-input>
          </b-col>
          <b-col cols="4">
            <b-input type="text" v-model="activityFilter" placeholder="Filtrer par activité">
            </b-input>
          </b-col>
          <b-col cols="4">
          </b-col>
        </b-row>
      </div>
      <div ref="printMe">
        <div class="hide-here">
          <div class="text-right">
            {{ now }}
          </div>
          <b>Inscriptions aux activités à l'essai</b>
        </div>
        <table class="table table-striped table-bordered">
          <tr v-if="inscriptions.length === 0">
            <td class="empty-text">Aucune inscription à l'essai</td>
          </tr>
          <tr v-if="inscriptions.length">
            <th>Date d'inscription</th>
            <th>Membre</th>
            <th>Activité</th>
            <th colspan="2">Échéance</th>
          </tr>
          <tr v-for="ins of filteredInscriptions" :key="ins.id">
            <td>{{ ins.createdOn | dateToString('ddd ll') }}</td>
            <td>
              <a :href="getEntityLink(ins)" @click.prevent="showIndividualSidebar(ins.individual)">
                {{ ins.individual.lastAndFirstName() }}
              </a>
            </td>
            <td>
              <router-link :to="getLinkToActivity(ins.activity, false)">
                {{ ins.activity.name }}
              </router-link>
            </td>
            <td>
              <div v-if="ins.tryingDate">{{ ins.tryingDate | dateToString('ddd ll') }}</div>
            </td>
            <td>
              <span class="badge badge-danger" v-if="ins.isTryingFinished()">
                Essai à l'échéance
              </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import { BackendMixin } from '@/mixins/backend'
import { ActivitiesMixin } from '@/mixins/activities'
import { makeActivityInscription } from '@/types/activities'
import { BackendApi, openDocument } from '@/utils/http'
import router from '@/router'

export default {
  name: 'trying-activity-inscriptions',
  props: {
  },
  mixins: [BackendMixin, ActivitiesMixin],
  components: {
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'trying-activity-inscriptions',
      inscriptions: [],
      entityFilter: '',
      activityFilter: '',
    }
  },
  async created() {
    await this.loadInscriptions()
  },
  watch: {
  },
  computed: {
    title() {
      let text = 'Inscriptions aux activités'
      const menu = this.getActivityMenu()
      if (menu && menu.category) {
        text += ' ' + menu.label
      }
      text += ' à l\'essai'
      return text
    },
    canView() {
      return this.hasPerm('activities.view_coreactivityinscription')
    },
    now() {
      return moment().format('ddd ll')
    },
    filteredInscriptions() {
      let inscriptions = this.inscriptions
      if (this.entityFilter) {
        inscriptions = inscriptions.filter(
          ins => ins.entity.name.toLowerCase().indexOf(this.entityFilter.toLowerCase()) >= 0
        )
      }
      if (this.activityFilter) {
        inscriptions = inscriptions.filter(
          ins => ins.activity.name.toLowerCase().indexOf(this.activityFilter.toLowerCase()) >= 0
        )
      }
      return inscriptions
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadInscriptions() {
      try {
        const url = '/api/activities/trying-inscriptions/?category=' + this.category
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.inscriptions = resp.data.map(elt => makeActivityInscription(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getEntityLink(ins) {
      const params = { entityId: '' + ins.entity.id, }
      const query = {
        tab: 'activities',
      }
      return router.resolve({ name: 'families-detail', params: params, query: query, }).href
    },
    async reload() {
      await this.loadInscriptions()
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'inscriptions-a-l-essai'
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: 'btn-secondary',
        },
        {
          id: 2,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: 'btn-secondary',
        }
      ]
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'inscriptions-a-l-essai'
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinkToActivity(activity, youth) {
      if (youth) {
        return { name: 'youth-homes-detail', params: { activityId: '' + activity.id, }, }
      } else {
        return { name: 'activities-detail', params: { activityId: '' + activity.id, }, }
      }
    },
  },
}
</script>

<style scoped lang="less">
.sub-menu {
  margin-bottom: 5px;
}
</style>
